import React, { useState, useEffect } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import axios from 'axios';
import BaseUrl from 'src/config';

const Timers = () => {
  const [serverTime, setServerTime] = useState<Date | null>(null);
  const [countdown, setCountdown] = useState<string>('');

  useEffect(() => {
    const fetchServerTime = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/server-time`);
        const currentTime = new Date(response.data.serverTime);
        setServerTime(currentTime);
        const roundedMinutes = Math.ceil(currentTime.getMinutes() / 5) * 5;
        const nextCountdownTime = new Date(currentTime);
        nextCountdownTime.setMinutes(roundedMinutes);
        nextCountdownTime.setSeconds(0);
        setCountdown(calculateCountdown(nextCountdownTime));
      } catch (error) {
        console.error('Error fetching server time:', error);
      }
    };

    // Fetch server time initially
    fetchServerTime();

    // Update server time every minute to keep it in sync
    const intervalId = setInterval(() => {
      fetchServerTime();
    }, 60000);

    return () => {
      clearInterval(intervalId); // Clean up the interval when component unmounts
    };
  }, []);

  useEffect(() => {
    if (!serverTime) return;

    const intervalId = setInterval(() => {
      const currentTime = new Date();
      const roundedMinutes = Math.ceil(currentTime.getMinutes() / 5) * 5;
      const nextCountdownTime = new Date(currentTime);
      nextCountdownTime.setMinutes(roundedMinutes);
      nextCountdownTime.setSeconds(0);
      setCountdown(calculateCountdown(nextCountdownTime));
    }, 1000); // Update every second

    return () => {
      clearInterval(intervalId);
    };
  }, [serverTime]);

  const calculateCountdown = (targetTime: Date): string => {
    const difference = targetTime.getTime() - new Date().getTime();
    if (difference < 0) {
      // If the target time is in the past, calculate the next target time
      const nextCountdownTime = new Date(targetTime);
      nextCountdownTime.setMinutes(nextCountdownTime.getMinutes() + 5);
      return calculateCountdown(nextCountdownTime);
    } else {
      const minutes = Math.floor((difference / 1000) / 60);
      const seconds = Math.floor((difference / 1000) % 60);
      return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    }
  };
  return (
    <div className='bg-black z-10 w-widthcon bg-opacity-10 backdrop-blur-lg  p-3 flex gap-5 mt-5 rounded-xl border border-white border-opacity-10 aspect-auto~ max-sm:grid max-sm:grid-cols-2'>
    
    
        <div data-tooltip-id="server" className='w-1/4 text-center bg-black bg-opacity-10 border border-white border-opacity-10 p-1 flex flex-col items-center justify-center rounded-lg max-sm:w-full'>
           <span className='mx-2 font-semibold'>שעון מקומי

           </span> {/* Safely access iron using optional chaining */}
             {serverTime ? serverTime.toLocaleTimeString('en-US', { hour12: false }) : 'Loading...'}
        </div>
        <div data-tooltip-id="rankupdate" className='w-1/4 text-center bg-black bg-opacity-10 border border-white border-opacity-10 p-1 flex flex-col items-center justify-center rounded-lg max-sm:w-full'>
           <span className='mx-2 font-semibold'>דירוג</span> {/* Safely access wood using optional chaining */}
           {countdown}
           
        </div>
        <div data-tooltip-id="daily" className='w-1/4 text-center bg-black bg-opacity-10 border border-white border-opacity-10 p-1 flex flex-col items-center justify-center rounded-lg max-sm:w-full'>
           <span className='mx-2 font-semibold'>יומי</span> {/* Safely access food using optional chaining */}
           15:23:30
        </div>
        <div data-tooltip-id="endofseason" className='w-1/4 text-center bg-black bg-opacity-10 border border-white border-opacity-10 p-1 flex flex-col items-center justify-center rounded-lg max-sm:w-full'>
           <span className='mx-2 font-semibold'>סוף עונה</span> {/* Safely access diamond using optional chaining */}
           28:05:00:00
        </div>
                <ReactTooltip
        style={{ backgroundColor: "rgb(55, 55, 55)", color: "#fff" }}
        id="server"
        place="bottom"
        variant="info"
        content="השעון של ישראל"
      />
                <ReactTooltip
        style={{ backgroundColor: "rgb(55, 55, 55)", color: "#fff" }}
        id="rankupdate"
        place="bottom"
        variant="info"
        content="עדכון דירוג (כל 5 דקות)"
      />
                <ReactTooltip
        style={{ backgroundColor: "rgb(55, 55, 55)", color: "#fff" }}
        id="daily"
        place="bottom"
        variant="info"
        content="עדכון יומי (כל יום בשעה 20:00)"
      />
                <ReactTooltip
        style={{ backgroundColor: "rgb(55, 55, 55)", color: "#fff" }}
        id="endofseason"
        place="bottom"
        variant="info"
        content="טיימר המציג את הזמן שנותר עד סוף העונה"
      />
      </div>
  )
}

export default Timers