import React, { useState, useEffect, Component } from 'react';
import "./Dashboard.scss"
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link, useParams } from 'react-router-dom';
 import { PieChart } from '@mui/x-charts/PieChart';
import News from '../components/News';
import BaseUrl from 'src/config';
import { formatNumber } from 'src/components/FormatNumber';
 interface id {
   id: string;
   nickname: string;
   city: string;
   gold: number;
   rank_city: number;
   rank_total: number;

   isAdmin: boolean;
  }
  
  const Test: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [player, setPlayer] = useState<id | null>(null);
    

  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        const response = await axios.get<id>(`${BaseUrl}/player/${id}`);
        setPlayer(response.data);
      } catch (error) {
        console.error('Error fetching player information:', error);
      }
    };

    fetchPlayer();
  }, [id]);

  if (!player) {
    return <div>
      
    </div>;
  }

  return (
   <>
   
   {player.id}
   </>
  );
};

const Dashboard = () => {
interface Player {
  id: string | undefined;
  nickname: string;
  city: string;
  gold: number;
  rank_city: number;
  turns: number;
  rank_total: number;
  isAdmin: boolean;
  spy_solider: number;
}




  function PlayerDashboard() {
    const [playerDashboard, setPlayerDashboard] = useState<any>(null); // Explicitly typing as any for now
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null); // Explicitly typing as Error or null

    const fetchPlayerDashboard = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BaseUrl}/stats`, {
          headers: {
            Authorization: token,
          },
        });
        setPlayerDashboard(response.data);
        setLoading(false);
      } catch (error: any) { // Explicitly typing error as any
        setError(error);
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchPlayerDashboard(); // Initial fetch

      const interval = setInterval(() => {
        fetchPlayerDashboard(); // Fetch data at regular intervals
      }, 5000); // Refresh every minute (adjust as needed)

      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);

    if (loading) {
      return <div><Skeleton /></div>;
    }

    if (error) {
      return <div>Error: {error.message}</div>; // Access error.message to display error message
    }
 let widthPercentage;

  switch (playerDashboard?.city) {
    case 'גושן':
      widthPercentage = '33%';
      break;
    case 'מדבר סיני':
      widthPercentage = '66%';
      break;
    case 'הארץ המובטחת':
      widthPercentage = '100%';
      break;
    default:
      widthPercentage = '0%';
  }
    const cityIndex = ['גושן', 'מדבר סיני', 'הארץ המובטחת'].indexOf(playerDashboard?.city) + 1;
  const totalCities = 3;
    








    return (
      <>
      <div className='w-widthcon flex flex-col m-5'>
        <div className='flex w-full gap-0 m-0 p-0'>
        <News />
        </div>
      <div className='bg-black bg-opacity-20 backdrop-blur-sm border border-1 border-opacity-10 border-white w-full p-3 rounded-xl'>
<h1 className='text-2xl'>שם הצבא: <Link className='font-bold from-red-500 to-orange-500 bg-gradient-to-r bg-clip-text text-transparent underline' to={`/profile/${playerDashboard.id}`}>{playerDashboard?.nickname}</Link></h1>
    <div className="relative h-5 rounded-lg overflow-hidden bg-white bg-opacity-10 mt-5 mx-15 border-2 p-3  outline-2"><div className='absolute flex justify-center items-center top-0 bottom-0 left-0 text-white w-full z-50 text-center'>{totalCities}/<span className='font-semibold'>{cityIndex}</span></div>
      <div className="text-white font-bold text-center absolute top-0 bottom-0 left-0 rounded-lg bg-white bg-opacity-20" style={{ width: widthPercentage }}>
        
      </div>
    </div>
    <div className='flex flex-row gap-5 text-center max-sm:flex-wrap max-sm:gap-1'>
      <div className='bg-black bg-opacity-20 w-2/6 mt-5 p-2 rounded-lg border-1 border-white border-opacity-10 border max-sm:w-full'>
<span className='mx-2 font-semibold '>עיר</span> {/* Safely access food using optional chaining */}<br />
           {playerDashboard.city}
      </div>
      <div className='bg-black bg-opacity-20 w-1/6 mt-5 p-2 rounded-lg border-1 border-white border-opacity-10 border max-sm:w-full max-sm:mt-1'><span className='mx-2 font-semibold'>דירוג בעיר</span> {/* Safely access food using optional chaining */}<br />
           {playerDashboard.rank_city}</div>
      <div className='bg-black bg-opacity-20 w-1/6 mt-5 p-2 rounded-lg border-1 border-white border-opacity-10 border max-sm:w-full max-sm:mt-1'><span className='mx-2 font-semibold'>דירוג כללי</span> {/* Safely access food using optional chaining */}<br />
           {playerDashboard.rank_total}</div>
      <div className='bg-black bg-opacity-20 w-1/6 mt-5 p-2 rounded-lg border-1 border-white border-opacity-10 border max-sm:w-full max-sm:mt-1'><span className='mx-2 font-semibold'>תורות</span> {/* Safely access food using optional chaining */}<br />
          {formatNumber(playerDashboard.turns)}</div>
      <div className='bg-black bg-opacity-20 w-1/6 mt-5 p-2 rounded-lg border-1 border-white border-opacity-10 border max-sm:w-full max-sm:mt-1'><span className='mx-2 font-semibold'>יהלומים</span> {/* Safely access food using optional chaining */}<br />
           {formatNumber(playerDashboard.diamond)}</div>
    </div>
      </div>
      
      </div>
      <div className='w-widthcon gap-5 flex flex-row max-sm:flex-col'>
        <div className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl max-sm:flex-col max-sm:justify-center max-sm:w-full'>
          <div className='flex flex-col justify-center w-full'>
            <div className='flex flex-row text-2xl items-center mb-5 mr-2'>
            <i className="fa fa-th-list"></i>
            משאבים
          </div>       
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-gold rounded-lg border border-white border-opacity-10'>
          זהב
          <br />
          {formatNumber(playerDashboard.gold)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-iron rounded-lg border border-white border-opacity-10'>
          ברזל
          <br />
          {formatNumber(playerDashboard.iron)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-wood rounded-lg border border-white border-opacity-10'>
          עץ
          <br />
          {formatNumber(playerDashboard.wood)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-food rounded-lg border border-white border-opacity-10'>
          אוכל
          <br />
          {formatNumber(playerDashboard.food)}
        </div>
        </div>
    <div className='flex justify-center items-center w-150 h-110'>
        <PieChart

  series={
    [
    
    {
      faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: playerDashboard.gold, color: '#f39c12', label: 'זהב' },
        { id: 1, value: playerDashboard.iron, color: '#00bc8c', label: 'ברזל' },
        { id: 2, value: playerDashboard.wood, color: '#e63131', label: 'עץ' },
        { id: 3, value: playerDashboard.food, color: '#31dce6', label: 'אוכל' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>

      </div>
<div className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl max-sm:flex-col max-sm:justify-center max-sm:w-full'>
          <div className='flex flex-col justify-center w-full'>
                        <div className='flex flex-row text-2xl items-center mb-5 mr-2'>
            <i className="fa fa-th-list"></i>
            משאבים בבנק
          </div>       
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-gold rounded-lg border border-white border-opacity-10'>
          זהב
          <br />
          {formatNumber(playerDashboard.bank_gold)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-iron rounded-lg border border-white border-opacity-10'>
          ברזל
          <br />
          {formatNumber(playerDashboard.bank_iron)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-wood rounded-lg border border-white border-opacity-10'>
          עץ
          <br />
          {formatNumber(playerDashboard.bank_wood)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-food rounded-lg border border-white border-opacity-10'>
          אוכל
          <br />
          {formatNumber(playerDashboard.bank_food)}
        </div>
        </div>
        <div className='flex justify-center items-center w-150 h-110'>
        <PieChart
        
  series={
    [
    
    {
                        faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: playerDashboard.bank_gold, color: '#f39c12', label: 'זהב בבנק' },
        { id: 1, value: playerDashboard.bank_iron, color: '#00bc8c', label: 'ברזל בבנק' },
        { id: 2, value: playerDashboard.bank_wood, color: '#e63131', label: 'עץ בבנק' },
        { id: 3, value: playerDashboard.bank_food, color: '#31dce6', label: 'אוכל בבנק' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>

      </div>
      </div>

      <div className='w-widthcon gap-5 flex flex-row max-sm:flex-col'>
        <div className='mt-5 bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl max-sm:flex-col max-sm:justify-center justify-center items-center max-sm:w-full'>
          <div className='flex flex-col justify-center w-full rounded-xl'>
            <div className='flex flex-row text-2xl items-center mb-5 mr-2'>
            <i className="fa fa-calculator"></i>
            עבדים
          </div>       
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-gold rounded-lg  sm:flex-col sm:justify-center border border-white border-opacity-10'>
          עובדי זהב
          <br />
          {formatNumber(playerDashboard.workers_gold)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-iron rounded-lg border border-white border-opacity-10'>
          עובדי ברזל
          <br />
          {formatNumber(playerDashboard.workers_iron)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-wood rounded-lg border border-white border-opacity-10'>
          עובדי עץ
          <br />
          {formatNumber(playerDashboard.workers_wood)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-food rounded-lg border border-white border-opacity-10'>
          עובדי אוכל
          <br />
          {formatNumber(playerDashboard.workers_food)}
        </div>
        </div>
        
    <div className='flex justify-center items-center w-150 h-110'>

        <PieChart
        
            
            series={[
                {
                  faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
                    data: [
                        { id: 0, value: playerDashboard.workers_gold, color: '#f39c12', label: 'עובדי זהב' },
                        { id: 1, value: playerDashboard.workers_iron, color: '#00bc8c', label: 'עובדי ברזל' },
                        { id: 2, value: playerDashboard.workers_wood, color: '#e63131', label: 'עובדי עץ' },
                        { id: 3, value: playerDashboard.workers_food, color: '#31dce6', label: 'עובדי אוכל' },
                    ],
                    innerRadius: 0,
                    outerRadius: 80,
                    paddingAngle: 0,
                    cornerRadius: 0,
                    
                }
            ]}
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}
        />

</div>

      </div>
        <div className='mt-5 bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl max-sm:flex-col max-sm:justify-center justify-center items-center max-sm:w-full'>
       
          <div className='flex flex-col justify-center w-full'>
                        <div className='flex flex-row text-2xl items-center mb-5 mr-2'>
            <i className="fa fa-calculator"></i>
            כח עבדים
          </div>       
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-gold rounded-lg border border-white border-opacity-10'>
          כח עובד זהב
          <br />
          {formatNumber(playerDashboard.gold_power)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-iron rounded-lg border border-white border-opacity-10'>
          כח עובד ברזל
          <br />
          {formatNumber(playerDashboard.iron_power)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-wood rounded-lg border border-white border-opacity-10'>
          כח עובד עץ
          <br />
          {formatNumber(playerDashboard.wood_power)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-food rounded-lg border border-white border-opacity-10'>
          כח עובד אוכל 
          <br />
          {formatNumber(playerDashboard.food_power)}
        </div>
        </div>
        <div className='flex justify-center items-center w-150 h-110'>
        <PieChart
        
  series={
    [
    
    {
       faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: playerDashboard.gold_power, color: '#f39c12', label: 'כח עובד זהב' },
        { id: 1, value: playerDashboard.iron_power, color: '#00bc8c', label: 'כח עובד ברזל' },
        { id: 2, value: playerDashboard.wood_power, color: '#e63131', label: 'כח עובד עץ' },
        { id: 3, value: playerDashboard.food_power, color: '#31dce6', label: 'כח עובד אוכל' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>

      </div>

      </div>

      <div className='w-widthcon gap-5 flex flex-row max-sm:flex-col mb-5'>
        <div className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl max-sm:flex-col max-sm:justify-center mt-5 max-sm:w-full'>
          <div className='flex flex-col justify-center w-full rounded-xl'>
            <div className='flex flex-row text-2xl items-center mb-5 mr-2'>
            <i className="fa fa-power-off"></i>
            כוחות
          </div>       
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2  rounded-lg border border-white border-opacity-10'>
          כח מודיעין
          <br />
          {formatNumber(playerDashboard.spy_power)}
        </div>
        <div className='w-full text-center mb-2 p-2 bg-iron bg-opacity-30 rounded-lg border border-white border-opacity-10'>
          כח התקפה
          <br />
          {formatNumber(playerDashboard.attack_power)}
        </div>
        <div className=' w-full text-center mb-2 p-2 bg-opacity-30 bg-wood rounded-lg border border-white border-opacity-10'>
          כח הגנה
          <br />
          {formatNumber(playerDashboard.defense_power)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 rounded-lg border border-white border-opacity-10'>
          כח כללי
          <br />
          {formatNumber(playerDashboard.main_power)}
        </div>
        </div>
        <div className='flex justify-center items-center w-150 h-110'>
        <PieChart
       
  series={
    [
    
    {
             faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: playerDashboard.spy_power, color: '#f39c12', label: 'כח מודיעין' },
        { id: 1, value: playerDashboard.attack_power, color: '#00bc8c', label: 'כח התקפה' },
        { id: 2, value: playerDashboard.defense_power, color: '#e63131', label: 'כח הגנה' },
        { id: 3, value: playerDashboard.main_power, color: '#31dce6', label: 'כח כללי' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>

      </div>
<div className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl max-sm:flex-col max-sm:justify-center mt-5 max-sm:w-full'>
          <div className='flex flex-col justify-center w-full'>
                        <div className='flex flex-row text-2xl items-center mb-5 mr-2'>
            <i className="fa fa-power-off"></i>
            כוחות לחייל
          </div>       
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 rounded-lg border border-white border-opacity-10'>
          כח מודיעין לחייל
          <br />
          {formatNumber(playerDashboard.spy_solider)}
        </div>
        <div className='w-full text-center mb-2 p-2 bg-iron bg-opacity-30 rounded-lg border border-white border-opacity-10'>
          כח התקפה לחייל
          <br />
          {formatNumber(playerDashboard.attack_solider)}
        </div>
        <div className=' w-full text-center mb-2 p-2 bg-wood bg-opacity-30 rounded-lg border border-white border-opacity-10'>
          כח הגנה לחייל
          <br />
          {formatNumber(playerDashboard.defense_solider)}
        </div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 rounded-lg border border-white border-opacity-10'>
          כח כללי לחייל
          <br />
          {formatNumber(playerDashboard.main_solider)}
        </div>
        </div>
        <div className='flex justify-center items-center w-150 h-110'>
        <PieChart
        

  series={
    [
    
    {
             faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: playerDashboard.spy_solider, color: '#f39c12', label: 'כח מודיעין לחייל' },
        { id: 1, value: playerDashboard.attack_solider, color: '#00bc8c', label: 'כח התקפה לחייל' },
        { id: 2, value: playerDashboard.defense_solider, color: '#e63131', label: 'כח הגנה לחייל' },
        { id: 3, value: playerDashboard.main_solider, color: '#31dce6', label: 'כח כללי לחייל' },
        
      ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}

/></div>

      </div>

      </div>
      <div className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-widthcon p-5 flex flex-row rounded-xl sm:flex-col sm:justify-center mb-5 max-sm:flex-col'>
          <div className='flex flex-col justify-center w-full'>
                        <div className='flex flex-row text-2xl items-center mb-5 mr-2'>
            <i className="fa fa-power-off"></i>
אוכלוסיה פעילה
          </div>       
        <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 rounded-lg text-gold border border-white border-opacity-10'>
          מגוייסים
          <br />
          {formatNumber(playerDashboard.enlisting)}
        </div>
        <div className='w-full text-center mb-2 p-2 bg-black bg-opacity-20 rounded-lg text-iron border border-white border-opacity-10'>
          חיילים
          <br />
          {formatNumber(playerDashboard.soliders)}
        </div>
        <div className='flex flex-row w-full gap-2'>
        <div className='w-1/2 text-center mb-2 p-2 bg-black bg-opacity-20 rounded-lg text-wood border border-white border-opacity-10'>
          עבדים
          <br />
          {formatNumber(playerDashboard.workers)}
        </div>
        
        <div className=' w-1/2 text-center mb-2 p-2 bg-black bg-opacity-20  rounded-lg text-food border border-white border-opacity-10'>
          עבדים ללא תעסוקה
          <br />
          {formatNumber(playerDashboard.workers_vac)}
        </div></div>
        <div className='bg-black bg-opacity-20 w-full text-center mb-5 p-2 rounded-lg text-diamond border border-white border-opacity-10'>
          מרגלים
          <br />
          {formatNumber(playerDashboard.spies)}
        </div>
        </div>
        <div className='flex justify-center items-center w-150 h-110'>
        <PieChart

  series={
    [
    
    {
             faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
     data: 
      [
        { id: 0, value: playerDashboard.enlisting, color: '#f39c12', label: 'מגוייסים' },
        { id: 1, value: playerDashboard.soliders, color: '#00bc8c', label: 'חיילים' },
        { id: 2, value: playerDashboard.workers, color: '#e63131', label: 'עבדים' },
        { id: 3, value: playerDashboard.workers_vac, color: '#3498db', label: 'עבדים ללא תעסוקה' },
        { id: 4, value: playerDashboard.spies, color: '#8e44ad', label: 'מרגלים' },
        
      ],
      innerRadius: 0,
      outerRadius: 100,
      paddingAngle: 0,
      cornerRadius: 0,
    }
    
  ]
  }
        margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
        }}
/></div>
</div>
     
      </>
    );
  }

  return (
    <PlayerDashboard />
  );
}

export default Dashboard;
