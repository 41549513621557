// index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "./AuthContext"; // Import AuthProvider
import RegisterForm from "./routes/Register";
import Login from "./routes/Login";
import Home from "./routes/Home";
import { ToastContainer } from 'react-toastify';
import Dashboard from "./routes/Dashboard";
import Profile from './routes/Profile'; // Import Profile component
import './routes/Dashboard.scss';
import Armies from "./routes/Armies";
import '.././node_modules/react-toastify/dist/ReactToastify.css';
import Letters from "./components/Letters";
import Inbox from "./components/Inbox";
import Outbox from "./components/Outbox";
import NewLetter from "./components/Newletter";
import SingleLetter from "./components/Singleletter";
import BuyWeapons from "./routes/AttackShop";
import BuyDefenseWeapons from "./routes/DefenseShop";
import BuyspyWeapons from "./routes/SpyShop";
import Nickname from "./components/Nickname";
import Loto from "./components/Loto";
import Admin from "./routes/Admin";
import Clan from "./routes/Clan";
import Playground from "./routes/Sidebar";
import CreateNews from "./Admin/Createnews";
import SingleNew from "./routes/SingleNew";
import Guess from "./routes/Guess";
import Minigames from "./routes/Minigames";
import Bank from "./routes/Bank";
import VerificationComponent from "./routes/Verify";
import Buy from "./routes/Buy";
import Orders from "./Admin/Orders";
import NotFound from "./routes/Errors/NotFound";
import Settings from "./routes/Settings";
import Prizes from "./routes/Prizes";
import Train from "./routes/Train";
import Work from "./routes/Work";
import ClanMark from "./components/ClanMark";
import Magic from "./routes/Magic";


// Create BrowserRouter instance
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      { index: true, path: "/", element: <Dashboard /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "armies", element: <Armies /> },
      { path: "profile/:id", element: <Profile /> },
      { path: "nickname", element: <Nickname /> },
      { path: "letters/inbox/", element: <Inbox /> },
      { path: "letters/outbox/", element: <Outbox /> },
      { path: "letters/:id", element: <SingleLetter /> },
      { path: "weapons/attack", element: <BuyWeapons /> },
      { path: "weapons/defense", element: <BuyDefenseWeapons /> },
      { path: "weapons/spy", element: <BuyspyWeapons /> },
      { path: "letters/new_letter", element: <NewLetter /> },
      { path: "lotto", element: <Loto /> },
      { path: "clan", element: <Clan /> },
      { path: "playground", element: <Playground /> },
      { path: "news/:id", element: <SingleNew /> },
      { path: "minigames", element: <Minigames /> },
      { path: "guess", element: <Guess /> },
      { path: "bank", element: <Bank /> },
      { path: "buy", element: <Buy /> },
      { path: "settings", element: <Settings /> },
      { path: "prizes", element: <Prizes /> },
      { path: "train", element: <Train /> },
      { path: "work", element: <Work /> },
      { path: "clanmark", element: <ClanMark /> },
      { path: "magic", element: <Magic /> },
      
      // admin
      { path: "hwadmin/news", element: <CreateNews /> },
      { path: "hwadmin/orders", element: <Orders /> },

    ],
  },
  { path: "/login", element: <Login /> },
  { path: "/register", element: <RegisterForm /> },
  { path: "/verify/:code", element: <VerificationComponent /> },  { path: "/hwadmin", element: <Admin /> },
    { path: "*", element: <NotFound /> },

]);

// Wrap your RouterProvider with AuthProvider
ReactDOM.createRoot(document.getElementById("root")!).render(
<React.StrictMode>
    <ToastContainer />
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
