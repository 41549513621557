import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import BaseUrl from 'src/config';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { getTimeDifference } from 'src/components/getTimeDiffrence';
import { rankColor, rankTitle } from 'src/components/getRankTitle';
import { io } from 'socket.io-client';

interface ClanData {
  clan_name: string;
  members: number;
  max_members: number;
  help: number;
  gold: number;
  clan_power?: number;
  taxes?: number;
  status?: string;
  chat_count?: number;
  memberscount?: number;
  members_list: ClanMember[]; // Ensuring this is an array
}
interface ClanMember {
  clan: string;
  rank_total: number;
  nickname: string;
  symbol: string;
  rank: any;
  paid: number;
  id: number;
  taxes: number;
}

interface ChatMessage {
  record: any;
  id: number;
  clan: string;
  rank: string;
  from_user: string;
  from_user_id: number;
  context: string;
  time: string;
  image?: string;
  replyTo?: number | null;
  replyToUser?: string | null;
  replyToMessage?: string | null;
}

interface JwtPayload {
  id: string;
  nickname: string;
}

const Clan: React.FC = () => {
const [clanData, setClanData] = useState<ClanData | null>(null);
  const [clanMembers, setClanMembers] = useState<ClanMember[]>([]);
  const [clanName, setClanName] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<'members' | 'chat'>('members');

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [canSendMessage, setCanSendMessage] = useState<boolean>(true);
  const [images, setImages] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [replyTo, setReplyTo] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [typingUser, setTypingUser] = useState<string | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
const [userRank, setUserRank] = useState<number | null>(null);
const [isRecording, setIsRecording] = useState(false);
const [audioUrl, setAudioUrl] = useState<string | null>(null);
const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const token = localStorage.getItem('token');
  const username = token ? jwtDecode<JwtPayload>(token).nickname : '';

const fetchData = async () => {
  try {
    const token = localStorage.getItem('token');
    let userRank = null;

    // Define and decode token only if it exists
    let decoded: JwtPayload | null = null;
    if (token) {
      decoded = jwtDecode<JwtPayload>(token);
    }
    

    const response = await axios.get(`${BaseUrl}/clan/members`, {
      headers: { Authorization: `${token}` },
    });

    setClanMembers(response.data);
    console.log(response.data);

    // Ensure decoded and id are defined before using them
    if (decoded) {
const userData = response.data.find((member: ClanMember) => member.id === parseInt(decoded?.id || '', 10));
      if (userData) {
        userRank = userData.rank;
        setUserId(userData.id);
        setUserRank(userRank);
      }
    }

    setClanName(response.data[0]?.clan || '');
  } catch (error) {
    console.error('Error fetching clan members:', error);
  }
};

  useEffect(() => {
    // Fetch clan and member data
    const fetchClans = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/clan/data`, {
      headers: { Authorization: `${token}` },
    });
        setClanData(response.data);
      } catch (error) {
        console.error('Error fetching clans:', error);
      }
    };
    fetchClans();
  }, []);

const startRecording = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
  const mediaRecorder = new MediaRecorder(stream);
  mediaRecorderRef.current = mediaRecorder;

  let chunks: BlobPart[] = [];
  mediaRecorder.ondataavailable = (event) => {
    chunks.push(event.data);
  };

  mediaRecorder.onstop = () => {
    const blob = new Blob(chunks, { type: 'audio/webm' });
    setAudioBlob(blob);
    setAudioUrl(URL.createObjectURL(blob));
    // You can handle the upload to server here if needed
  };

  mediaRecorder.start();
  setTimeout(() => {
    mediaRecorder.stop();
    setIsRecording(false);
  }, 120000); // Automatically stop after 2 minutes

  setIsRecording(true);
};

const stopRecording = () => {
  if (mediaRecorderRef.current) {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  }
};
  const fetchChatMessages = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/clan/chat`, {
        headers: { Authorization: `${token}` },
        params: { clan: clanName },
      });
      setMessages(response.data.reverse());
    } catch (error) {
      console.error('Error fetching chat messages:', error);
    }
  };

  const handleReply = (messageId: React.SetStateAction<number | null>) => {
    setReplyTo(messageId);
  };

  const handleDelete = async (messageId: number) => {
    if (confirm("Are you sure you want to delete this message?")) {
      try {
        await axios.delete(`${BaseUrl}/clan/chat/${messageId}`, {
          headers: { Authorization: `${token}` },
        });
        fetchChatMessages();
      } catch (error) {
        console.error('Error deleting message:', error);
      }
    }
  };

const socket = useRef(io('http://localhost:3002'));

// In useEffect
useEffect(() => {
  fetchData();

  // Connect to Socket.IO and set up event listeners
  socket.current.on('connect', () => console.log('Socket.IO connected'));

  socket.current.on('newMessage', () => {
    fetchChatMessages();
  });

  socket.current.on('typing', (data: { from_user: string }) => {
    setTypingUser(data.from_user);

    // Clear typing indication after 3 seconds
    setTimeout(() => {
      setTypingUser(null);
    }, 3000);
  });

  // Clean up event listeners on unmount
  return () => {
    socket.current.off('connect');
    socket.current.off('newMessage');
    socket.current.off('typing');
  };
}, [clanName]);



const sendMessage = async () => {
  if (!canSendMessage || (!newMessage && images.length === 0 && !audioBlob)) return;

  const formData = new FormData();
  formData.append('message', newMessage || '');
  formData.append('clan', clanName || '');
  if (replyTo) formData.append('replyTo', replyTo.toString());
  images.forEach((image) => formData.append('images', image));
  if (audioBlob) formData.append('record', audioBlob);

  try {
    await axios.post(`${BaseUrl}/clan/chat`, formData, {
      headers: { Authorization: `${token}`, 'Content-Type': 'multipart/form-data' },
    });
    setNewMessage('');
    setImages([]);
    setImagePreviews([]);
    setReplyTo(null);
    setAudioBlob(null);
    fetchChatMessages();
    setCanSendMessage(false);
    setTimeout(() => setCanSendMessage(true), 5000);
  } catch (error) {
    console.error('Error sending message:', error);
  }
};

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    if (selectedFiles.length + images.length <= 3) {
      setImages((prev) => [...prev, ...selectedFiles]);
      setImagePreviews((prev) => [
        ...prev,
        ...selectedFiles.map((file) => URL.createObjectURL(file)),
      ]);
    } else {
      console.error('You can only upload up to 3 images.');
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
    setImagePreviews((prev) => prev.filter((_, i) => i !== index));
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files || []);
    if (droppedFiles.length + images.length <= 3) {
      setImages((prev) => [...prev, ...droppedFiles]);
      setImagePreviews((prev) => [
        ...prev,
        ...droppedFiles.map((file) => URL.createObjectURL(file)),
      ]);
    } else {
      console.error('You can only upload up to 3 images.');
    }
  };

const handleTyping = () => {
  // Check if the socket is connected and emit the typing event
  if (socket.current?.connected && username) {
    socket.current.emit('typing', { from_user: username });
  }
};

  const openImageViewer = (src: string) => {
    setSelectedImage(src);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setSelectedImage(null);
    setIsImageViewerOpen(false);
  };

  const renderReplyMessage = (messageId: number) => {
    const replyMessage = messages.find((msg) => msg.id === messageId);
    if (!replyMessage) return null;

    let imagePaths: string[] = [];
    if (replyMessage.image) {
      try {
        imagePaths = JSON.parse(replyMessage.image);
      } catch (error) {
        console.error('Failed to parse image paths:', error);
      }
    }

    return (
      <div className="p-2 rounded bg-gray-700 shadow-md text-white flex flex-col">
        <strong className={`text-nickname font-normal ${rankColor(replyMessage.rank)}`}>
          <Link to={`/profile/${replyMessage.from_user_id}`} className="hover:underline">
            {replyMessage.from_user}
          </Link>
        </strong>
        {formatMessage(replyMessage.context)}
        {imagePaths.length > 0 && (
          <div className="flex gap-2 mt-2 flex-col bg-gray-600 rounded-lg p-2">
            <span>תמונה/ תמונות מצורפות:</span>
            <div className='flex flex-row gap-2'>
              {imagePaths.map((src, index) => (
                <div key={index} className="cursor-pointer" onClick={() => openImageViewer(src)}>
                  <img src={`${src}`} alt={`attachment ${index}`} className="w-10 h-10 object-cover rounded" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const formatMessage = (message: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.split(' ').map((part, index) =>
      urlRegex.test(part) ? (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
          {part}
        </a>
      ) : (
        part + ' '
      )
    );
  };

  return (
    <div className="w-widthcon mx-auto flex flex-col my-5">
      <div className="tabs flex justify-center mt-4">
        <button onClick={() => setActiveTab('members')} className={`p-2 ${activeTab === 'members' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}>
          Members
        </button>
        <button onClick={() => { setActiveTab('chat'); fetchChatMessages(); }} className={`p-2 ${activeTab === 'chat' ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}>
          Chat
        </button>
      </div>
{activeTab === 'members' && (
  <div className="space-y-4">
    {/* Alliance Name and Ranking */}
    <div className="bg-gray-700 p-4 rounded-lg shadow">
      <div className="flex justify-between mb-4">
        <span className="text-lg font-semibold text-white">
          שם הברית: <a className="text-yellow-500">{clanData?.clan_name}</a>
        </span>
        {/* Uncomment if ranking display is required */}
        {/* <span className="text-lg font-semibold text-white">דירוג הברית: {clanData?.rank || '1'}</span> */}
      </div>
      
      {/* Alliance Stats */}
      <div className="flex flex-wrap space-x-4 space-y-4">
        <div className="flex-1 bg-gray-800 p-2 rounded-lg">
          <span className="text-white">חברים: </span>
          <span className="text-yellow-500">{clanData?.memberscount} / {clanData?.max_members}</span>
        </div>
        <div className="flex-1 bg-gray-800 p-2 rounded-lg">
          <span className="text-white">עזרה: </span>
          <span className="text-yellow-500">{clanData?.help}%</span>
        </div>
        <div className="flex-1 bg-gray-800 p-2 rounded-lg">
          <span className="text-white">זהב: </span>
          <span className="text-yellow-500">{clanData?.gold}</span>
        </div>
        <div className="flex-1 bg-gray-800 p-2 rounded-lg">
          <span className="text-white">כוח הברית: </span>
          <span className="text-yellow-500">{clanData?.clan_power || 0}</span>
        </div>
      </div>
    </div>

    {/* Alliance Taxes and Status */}
    <div className="bg-gray-700 p-4 rounded-lg shadow space-y-4">
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <span className="text-yellow-500">מיסי הברית:</span>
          <span className="text-yellow-500">{clanData?.taxes || 0}</span>
        </div>
        <div className="flex items-center space-x-2">
          <span className="text-yellow-500">סטטוס הברית:</span>
          <span className="text-white">{clanData?.status || 'אין סטטוס'}</span>
          <a href="/guild_manage" className="text-yellow-500 flex items-center space-x-1">
            <i className="fa fa-pencil" />
            <span className="hidden sm:inline">ערוך סטטוס</span>
          </a>
        </div>
      </div>
    </div>

    {/* Alliance Navigation Links */}
    <div className="bg-gray-700 p-4 rounded-lg shadow">
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-4 text-center">
        <a href="/guild_get_invite" className="bg-gray-800 p-3 rounded-lg text-white font-bold">
          הזמנות
        </a>
        <a href="/guild" className="bg-white p-3 rounded-lg text-gray-800 font-bold">
          בסיס הברית
        </a>
<a
  href="/guild_chat"
  className={`p-2 rounded-lg text-white font-bold`}
  onClick={(e) => {
    e.preventDefault();
    setActiveTab('chat'); // Update activeTab correctly
    fetchChatMessages();
  }}
>
  צ'אט הברית <span className="bg-red-500 p-1 rounded-full text-white ml-2">{clanData?.chat_count || 0}</span>
</a>
<button onClick={() => { setActiveTab('chat'); fetchChatMessages(); }}>
          Chat
        </button>

        <a href="/guild_bank" className="bg-gray-800 p-3 rounded-lg text-white font-bold">
          בנק הברית
        </a>
        <a href="/guild_manage" className="bg-gray-800 p-3 rounded-lg text-white font-bold">
          ניהול הברית
        </a>
        <a href="/quit_guild" className="bg-gray-800 p-3 rounded-lg text-white font-bold">
          יציאה מהברית
        </a>
      </div>
    </div>

    {/* Alliance Members Table */}
    <div className="bg-gray-700 p-4 rounded-lg shadow">
      <table className="min-w-full divide-y divide-gray-600">
        <thead>
          <tr>
            <th></th>
            <th className="text-white px-4 py-2">דירוג</th>
            <th className="text-white px-4 py-2">שם הצבא</th>
            <th className="text-white px-4 py-2">תרם לברית</th>
            <th className="text-white px-4 py-2">פעולות</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-600">
{clanData?.members_list?.map((member: ClanMember) => (
  <tr key={member.id}>
    <td className="text-center text-green-500">
      <i className="fa fa-user" title="מחובר"></i>
    </td>
    <td className="text-white text-center">{member.rank}</td>
    <td className="text-white text-center">
      <strong>
        <a href={`profile/${member.id}`} className="text-yellow-500">{member.nickname}</a>
      </strong>
    </td>
    <td className="text-white text-center">{member.paid}</td>
    <td className="text-white text-center"></td>
  </tr>
))}
        </tbody>
      </table>
    </div>
  </div>
)}


      {activeTab === 'chat' && (
        <div className="chat mt-5">
          {replyTo && (
            <div className="mb-2 p-2 rounded bg-gray-800 shadow-md text-white">
              <span>הגב ל {messages.find(msg => msg.id === replyTo)?.from_user || ''}</span>
              <div className="mt-2 text-gray-600">
                {renderReplyMessage(replyTo)}
              </div>
              <button onClick={() => setReplyTo(null)} className="text-red-500 ml-2">
                &times;
              </button>
            </div>
          )}

          <div className="chat-messages p-4 h-96 bg-gray-700 shadow-md max-h-[400px] overflow-y-auto
            [&::-webkit-scrollbar]:w-2
            [&::-webkit-scrollbar-track]:rounded-full
            [&::-webkit-scrollbar-track]:bg-gray-100
            [&::-webkit-scrollbar-thumb]:rounded-full
            [&::-webkit-scrollbar-thumb]:bg-gray-300
            dark:[&::-webkit-scrollbar-track]:bg-neutral-700
            dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500" 
            style={{ direction: 'rtl' }}
          >
  {typingUser && <div className="text-sm font-bold italic mb-2 text-white">{`${typingUser} מקליד...`}</div>}
            {messages.map((msg) => (
  <div key={msg.id} className="chat-message">
    {/* Existing content */}
    {msg.record && (
      <audio controls className="w-full mt-2">
        <source src={msg.record} type="audio/webm" />
        Your browser does not support the audio element.
      </audio>
    )}
  </div>
))}
            {messages.map((msg) => (
<div 
  key={msg.id} 
  className="mb-2 p-2 rounded bg-gray-800 shadow-md text-white text-right flex flex-col relative" 
  onContextMenu={(e) => {
    e.preventDefault(); // Prevent default right-click menu
    setDropdownVisible((prev) => (prev === msg.id ? null : msg.id));
  }}
>
  <div className="grid grid-cols-[1fr_auto_auto] items-center gap-2">
    <strong className={`text-nickname ${rankColor(msg.rank)}`}>
      <Link to={`/profile/${msg.from_user_id}`} className="hover:underline">
        {msg.from_user}
      </Link>
      <span className="mr-1 font-normal">({rankTitle(msg.rank)})</span>
    </strong>
    <span className="text-sm">{getTimeDifference(msg.time)}</span>
    
    <button 
      onClick={() => setDropdownVisible((prev) => (prev === msg.id ? null : msg.id))}
      className="p-2"
      aria-expanded="true"
    >
      <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 4 15">
        <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"/>
      </svg>
    </button>

    {dropdownVisible === msg.id && (
      <div id="dropdownDots" className="z-10 bg-white divide-y rounded-lg shadow dark:bg-gray-700 w-40 absolute top-12 left-1">
        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
          <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">
            <a href="#" onClick={() => handleReply(msg.id)}>
              הגב
            </a>
          </li>
          {((msg.from_user_id === userId && userRank === 1) || ['2', '3'].includes(String(userRank))) && (
            <li className="px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600">
              <a href="#" onClick={() => handleDelete(msg.id)}>
                מחק הודעה
              </a>
            </li>
          )}
        </ul>
      </div>
    )}
  </div>

  <div>{formatMessage(msg.context)}</div>

  {msg.replyTo && (
    <div className="mt-2">
      {renderReplyMessage(msg.replyTo)}
    </div>
  )}

  {msg.image && JSON.parse(msg.image).length > 0 && (
    <div className="flex gap-2 mt-2 flex-col bg-black bg-opacity-10 rounded-lg p-2">
      <span>{JSON.parse(msg.image).length === 1 ? "תמונה מצורפת" : "תמונות מצורפות"}</span>
      <div className="flex flex-row gap-2">
        {JSON.parse(msg.image).map((src: string, index: number) => (
          <div key={index} className="cursor-pointer" onClick={() => openImageViewer(src)}>
            <img src={`${src}`} alt={`attachment ${index}`} className="w-10 h-10 object-cover rounded" />
          </div>
        ))}
      </div>
    </div>
  )}
</div>

            ))}
          </div>


<form onSubmit={(e) => { e.preventDefault(); sendMessage(); }}>
  <div className={`grid grid-cols-[auto,auto,1fr,auto] items-center py-2 px-3 bg-gray-50 border-t border-t-gray-500 dark:bg-gray-700 ${isDragging ? 'border-2 border-dashed border-blue-500' : ''}`} 
       onDrop={handleDrop} 
       onDragOver={handleDragOver} 
       onDragLeave={handleDragLeave}
  >
    <button type="button" onClick={() => document.getElementById('file-input')?.click()}
            className="p-2 text-gray-500 rounded-lg cursor-pointer hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600">
      <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd"></path>
      </svg>
      <input id="file-input" type="file" accept="image/*" multiple onChange={handleImageUpload} className="hidden" />
    </button>

    {/* Audio Recording Button */}
    <button 
      type="button" 
      onClick={() => (isRecording ? stopRecording() : startRecording())}
      className={`w-10 h-10 p-2 rounded-lg fill-gray-400 hover:fill-gray-100 cursor-pointer ${isRecording ? 'text-red-500 bg-red-100' : 'text-gray-500 hover:text-gray-900 hover:bg-gray-100'} dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600`}
    >
      {isRecording ? (
        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20">
          <path d="M3 3h14v14H3V3z" /> {/* Stop icon */}
        </svg>
      ) : (
<button className="mic-icon-button">
  <svg height="24px" width="24px" viewBox="0 0 341.981 341.981" xmlns="http://www.w3.org/2000/svg">
    <path d="M170.991,260.395c40.113,0,72.747-32.634,72.747-72.747v-114.9C243.738,32.634,211.104,0,170.991,0 S98.243,32.634,98.243,72.747v114.9C98.243,227.761,130.877,260.395,170.991,260.395z M119.999,72.747 c0-28.109,22.877-50.991,50.991-50.991s50.991,22.882,50.991,50.991v114.9c0,28.12-22.877,50.991-50.991,50.991 s-50.991-22.871-50.991-50.991V72.747z"/>
    <path d="M189.766,297.109c48.506,0,87.966-39.455,87.966-87.961c0-6.01-4.873-10.878-10.878-10.878 s-10.878,4.868-10.878,10.878c0,36.502-29.703,66.204-66.21,66.204h-37.551c-36.507,0-66.21-29.703-66.21-66.204 c0-6.01-4.873-10.878-10.878-10.878c-6.005,0-10.878,4.868-10.878,10.878c0,48.506,39.46,87.961,87.966,87.961h7.898v23.116 h-50.991c-6.005,0-10.878,4.873-10.878,10.878c0,6.01,4.873,10.878,10.878,10.878H232.86c6.005,0,10.878-4.868,10.878-10.878 c0-6.005-4.873-10.878-10.878-10.878h-50.991v-23.116H189.766z"/>
  </svg>
</button>
      )}
    </button>
    
    {/* Other form elements like your textarea and submit button */}
    <textarea
      id="chat"
      rows={1}
      value={newMessage}
      onChange={(e) => setNewMessage(e.target.value)}
      onKeyPress={handleTyping}
      className="block mx-4 p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      placeholder="ההודעה שלך..."
      style={{ direction: 'rtl' }}
    />
    <button type="submit" className="inline-flex justify-center p-2 text-nickname rounded-full cursor-pointer hover:bg-blue-100 dark:text-blue-500 dark:hover:bg-gray-600 mr-6">
      <svg className="w-6 h-6 rotate-90" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a 1 1 0 112 0v4.571a 1 1 0 00.725.962l5 1.428a 1 1 0 001.17-1.408l-7-14z"></path>
      </svg>
    </button>
  </div>
</form>
          
        </div>
      )}

      {isImageViewerOpen && selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <button onClick={closeImageViewer} className="absolute top-0 right-4 text-red-500 text-6xl animate-pulse">
            &times;
          </button>
          <img src={`${selectedImage}`} alt="Fullscreen View" className="max-w-full max-h-full rounded shadow-lg" />
        </div>
      )}
    </div>
  );
};

export default Clan;
