import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Icon from "../images/logo.png";
import BaseUrl from 'src/config';
import { getTimeDifference } from 'src/components/getTimeDiffrence';

const CreateNews = () => {
    const [title, setTitle] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [image, setImage] = useState<string>('');
    const [tags, setTags] = useState<any[]>([]);
    const [selectedTags, setSelectedTags] = useState<any[]>([]);
    const [livePreview, setLivePreview] = useState<string>('');

    // Fetch available tags from backend
    useEffect(() => {
        axios.get(`${BaseUrl}/tags`)
            .then(response => {
                setTags(response.data);
            })
            .catch(error => {
                console.error('Error fetching tags:', error);
            });
    }, []);

    // Function to handle tag selection
    const handleTagSelection = (tag: any) => {
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((selectedTag: any) => selectedTag !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };

    // Function to handle news creation
const handleCreateNews = () => {
    // Validate that the title is not empty
    if (!title) {
        console.error('Title cannot be empty');
        return;
    }

    // Prepare the data to be sent in the request body
    const requestData = {
        title: title,
        description: description,
        imageUrl: image,
        tags: selectedTags
    };

    // Send POST request to backend
    axios.post(`${BaseUrl}/news`, requestData)
        .then(response => {
            console.log('News created successfully:', response.data);
            // Clear form fields after successful submission
            setTitle('');
            setDescription('');
            setImage('');
            setSelectedTags([]);
            setLivePreview('');
        })
        .catch(error => {
            console.error('Error creating news:', error);
        });
};

// Update the image state when the user inputs an image URL


    // Function to update live preview
    useEffect(() => {
        const selectedTagObjects = selectedTags.map(tagId => tags.find(tag => tag.id === tagId));
    let previewContent = '';

    if (image && typeof image === 'object') {
        previewContent = `<img class="w-full rounded-xl" src="${URL.createObjectURL(image)}" alt="תמונת רקע חדשות" />`;
    } else if (typeof image === 'string') {
        previewContent = `<img class="w-full rounded-xl" src="${image}" alt="תמונת רקע חדשות" />`;
    }
        // Generate HTML for selected tags
        const selectedTagsHTML = selectedTagObjects.map((tag, index) => (
            `<span key="${tag.id}" class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 ml-2 ${index !== 0 ? 'ml-2' : ''}">${tag.tag_name}</span>`
        )).join('');

        setLivePreview(`
            <div class="max-w-sm rounded-xl overflow-hidden shadow-lg mb-5 border border-white border-opacity-10 backdrop-blur-lg">
                <div class='p-5'>
                    ${previewContent}
                </div>
                <div class="px-6 py-4">
                    <div class='flex items-center gap-2'>
                        <img src="${Icon}" width="64" alt="אייקון" />
                        <div class="font-bold text-xl">${title}
                            <p class="text-white text-base font-light">${getTimeDifference(new Date().toString())}</p>
                        </div>
                    </div>
                    <div class='w-full bg-white bg-opacity-10 h-px mt-4'></div>
                    ${description && `<p class="text-white text-base mt-5">${description}</p>`}
                    <div class="mt-4">
                        ${selectedTagsHTML}
                    </div>
                </div>
            </div>
        `);
    }, [title, description, selectedTags, tags, image]);


    return (
<div className='w-widthcon flex flex-col items-center bg-black bg-opacity-50 p-10 rounded-lg shadow-lg'>
    <h1 className='text-4xl font-bold text-white mb-10 mt-5'>צור עדכון חדש</h1>
    <div className='w-full max-w-2xl'>
        <div className='mb-6'>
            <label className='text-lg font-medium text-gray-300 block mb-2'>כותרת:</label>
            <input 
                className='border border-gray-500 focus:border-wood w-full bg-gray-700 bg-opacity-40 p-3 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-wood transition duration-150' 
                type="text" 
                value={title} 
                onChange={(e) => setTitle(e.target.value)} 
                placeholder="הזן כותרת לעדכון"
            />
        </div>
        <div className='mb-6'>
            <label className='text-lg font-medium text-gray-300 block mb-2'>תיאור:</label>
            <textarea 
                className='border border-gray-500 focus:border-wood w-full bg-gray-700 bg-opacity-40 p-3 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-wood transition duration-150' 
                value={description} 
                onChange={(e) => setDescription(e.target.value)}
                placeholder="הזן תיאור לעדכון"
            ></textarea>
        </div>
        <div className='mb-6'>
            <label className='text-lg font-medium text-gray-300 block mb-2'>קישור לתמונת רקע:</label>
            <input 
                className='border border-gray-500 focus:border-wood w-full bg-gray-700 bg-opacity-40 p-3 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-wood transition duration-150'
                type="text" 
                onChange={(e) => {
                    const imageUrl = e.target.value;
                    setImage(imageUrl); 
                }} 
                placeholder="הכנס קישור לתמונת רקע" 
            />
        </div>
        <div className='mb-6'>
            <label className='text-lg font-medium text-gray-300 block mb-2'>תגיות:</label>
            <div className='flex flex-wrap gap-4'>
                {tags.map(tag => (
                    <label key={tag.id} className='flex items-center space-x-2'>
                        <input 
                            type="checkbox" 
                            checked={selectedTags.includes(tag.id)} 
                            onChange={() => handleTagSelection(tag.id)} 
                            className="accent-desert w-4 h-4 rounded focus:ring-2 focus:ring-offset-2 focus:ring-desert"
                        />
                        <span className='text-gray-300'>{tag.tag_name}</span>
                    </label>
                ))}
            </div>
        </div>
        <button 
            className='mt-5 mb-5 bg-gradient-to-r from-wood to-gold hover:from-yellow-600 hover:to-gold transition-all duration-200 p-3 rounded-lg text-white font-semibold shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2'
            onClick={handleCreateNews}
        >
            יצירת עדכון
        </button>
    </div>
    <div>
        <h2 className='text-2xl font-semibold text-white mb-5'>תצוגה מקדימה:</h2>
        <div dangerouslySetInnerHTML={{ __html: livePreview }} className="flex gap-5 justify-center items-center align-middle"></div>
    </div>
</div>

    );
}

export default CreateNews;
