import React, { useState, useEffect } from 'react';
import "./Stats.scss"
import Gold from "../images/gold.png"
import Iron from "../images/iron.png"
import Diamond from "../images/diamond.png"
import Food from "../images/food.png"
import Turns from "../images/turns.png"
import Wood from "../images/tree.png"
import Rank from "../images/rank.png"
import Solider from "../images/solider.png"
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BaseUrl from 'src/config';
import { formatNumberLetters } from './FormatNumber';
interface id {
  id: string;
  nickname: string;
  city: string;
  gold: number;
  bank_gold: number;
  rank_city: number;
  rank_total: number;
  isAdmin: boolean;
}

const Test: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [player, setPlayer] = useState<id | null>(null);

  useEffect(() => {
    const fetchPlayer = async () => {
      try {
        const response = await axios.get<id>(`${BaseUrl}/player/${id}`);
        setPlayer(response.data);
      } catch (error) {
        console.error('Error fetching player information:', error);
      }
    };

    fetchPlayer();
  }, [id]);

  if (!player) {
    return <div>
      
    </div>;
  }

  return (
   <>
   
   {player.id}
   </>
  );
};

const Stats = () => {
interface Player {
  id: string | undefined;
  nickname: string;
  city: string;
  gold: number;
  rank_city: number;
  turns: number;
  rank_total: number;
  isAdmin: boolean;
}




  function PlayerStats() {
    const navigate = useNavigate();
    const [playerStats, setPlayerStats] = useState<any>(null); // Explicitly typing as any for now
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null); // Explicitly typing as Error or null

    const fetchPlayerStats = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${BaseUrl}/stats`, {
          headers: {
            Authorization: token,
          },
        });
        
        setPlayerStats(response.data);
        setLoading(false);
      } catch (error: any) { 
if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      navigate('/login')
    } else {
      setError(error);
    }
        setError(error);
        setLoading(false);
      }
    };

    useEffect(() => {
      fetchPlayerStats(); // Initial fetch

      const interval = setInterval(() => {
        fetchPlayerStats(); // Fetch data at regular intervals
      }, 5000); // Refresh every minute (adjust as needed)

      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);
useEffect(() => {
  // Check if playerStats is defined and nickname is null or undefined
  if (playerStats && (playerStats.nickname === null || playerStats.nickname === undefined)) {
    // Check current URL to see if already redirected
    if (!window.location.pathname.endsWith('/nickname')) {
      navigate('/nickname');
    }
  }
}, [playerStats, navigate]);


    if (loading) {
      return <div><Skeleton /></div>;
    }

    if (error) {
      return <div>Error: {error.message}</div>; // Access error.message to display error message
    }

    // Render player stats once loaded
    return (
      <div className='w-widthcon z-20 bg-white bg-opacity-5 border border-white border-opacity-10 backdrop-blur-lg p-3 flex gap-5 mt-5 rounded-xl max-md:flex-wrap max-md:justify-start aspect-auto max-sm:grid max-sm:grid-cols-3'>
        <div data-tooltip-id="rating" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="15px" src={Solider} />
          <span className='mx-2 text-red-500'><Link to={`/profile/${playerStats.id}`}>{playerStats?.nickname}</Link></span> {/* Safely access rank_city using optional chaining */}
        </div>
        <div data-tooltip-id="rank" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="15px" src={Rank} />
          <span className='mx-2 text-white'>{formatNumberLetters(playerStats?.rank_city)}</span> {/* Safely access rank_city using optional chaining */}
        </div>
        <div data-tooltip-id="turns" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="15px" src={Turns} />
          <span className='mx-2 text-white'>{formatNumberLetters(playerStats?.turns)}</span> {/* Safely access rank_city using optional chaining */}
        </div>
        <div data-tooltip-id="gold" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="15px" src={Gold} />
           <span className='mx-2 text-gold'>{formatNumberLetters(playerStats?.gold)}</span> {/* Safely access gold using optional chaining */}
        </div>
        <div data-tooltip-id="iron" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="15px" src={Iron} />
           <span className='mx-2 text-iron'>{formatNumberLetters(playerStats?.iron)}</span> {/* Safely access iron using optional chaining */}
        </div>
        <div data-tooltip-id="wood" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="15px" src={Wood} />
           <span className='mx-2 text-wood'>{formatNumberLetters(playerStats?.wood)}</span> {/* Safely access wood using optional chaining */}
        </div>
        <div data-tooltip-id="food" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="12px" src={Food} />
           <span className='mx-2 text-food'>{formatNumberLetters(playerStats?.food)}</span> {/* Safely access food using optional chaining */}
        </div>
        <div data-tooltip-id="diamonds" className='w-1-7 text-center bg-black bg-opacity-10 p-1 flex items-center justify-center rounded-lg border border-white border-opacity-10 max-sm:w-full'>
          <img width="15px" src={Diamond} />
           <span className='mx-2 text-diamond'>{formatNumberLetters(playerStats?.diamond)}</span> {/* Safely access diamond using optional chaining */}
        </div>
        <ReactTooltip
        style={{ backgroundColor: "rgb(255, 0, 0)", color: "#fff" }}
        id="rating"
        place="bottom"
        variant="info"
        content="הכינוי שלך במשחק"
      />
        <ReactTooltip
        style={{ backgroundColor: "rgb(55, 55, 55)", color: "#fff" }}
        id="rank"
        place="bottom"
        variant="info"
        content="הדירוג שלך בעיר"
      />
        <ReactTooltip
        style={{ backgroundColor: "rgb(55, 55, 55)", color: "#fff" }}
        id="turns"
        place="bottom"
        variant="info"
        content="כמות התורות שברשותך"
      />
        <ReactTooltip
        style={{ backgroundColor: "gold", color: "#000" }}
        id="gold"
        place="bottom"
        variant="info"
        content="כמות הזהב שברשותך"
      />
        <ReactTooltip
        style={{ backgroundColor: "silver", color: "#000" }}
        id="iron"
        place="bottom"
        variant="info"
        content="כמות הברזל שברשותך"
      />

        <ReactTooltip
        style={{ backgroundColor: "burlywood", color: "#000" }}
        id="wood"
        place="bottom"
        variant="info"
        content="כמות העץ שברשותך"
      />
        <ReactTooltip
        style={{ backgroundColor: "orange", color: "#000" }}
        id="food"
        place="bottom"
        variant="info"
        content="כמות האוכל שברשותך"
      />
        <ReactTooltip
        style={{ backgroundColor: "purple", color: "#fff" }}
        id="diamonds"
        place="bottom"
        variant="info"
        content="כמות היהלומים שברשותך"
      />
      </div>
    );
  }

  return (
    <PlayerStats />
  );
}

export default Stats;
