import React, { useState, useEffect } from 'react';
import axios from 'axios';
import BaseUrl from 'src/config';
import { toast } from 'react-toastify';
import { formatNumber } from './../components/FormatNumber';

interface Player {
  email: string;
  id: number;
  nickname: string;
  workers: number;
  workers_vac: number;
  soliders: number;
  spies: number;
  enlisting: number;
}

const Train: React.FC = () => {
  const [player, setPlayer] = useState<Player>({
    email: '',
    id: 0,
    nickname: '',
    workers: 0,
    workers_vac: 0,
    soliders: 0,
    spies: 0,
    enlisting: 0,
  });

  const [additions, setAdditions] = useState({
    workersAdd: 0,
    workersVacAdd: 0,
    solidersAdd: 0,
    spiesAdd: 0,
  });

  const [city, setCity] = useState<string>('Unknown City');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPlayer = async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        setError('No token found');
        setLoading(false);
        return;
      }

      const response = await axios.get(`${BaseUrl}/train`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      setPlayer(response.data.player || player); 
      setCity(response.data.city || 'Unknown City');
      setLoading(false);
    } catch (err) {
      console.error('Error fetching player:', err);
      setError('Failed to load player');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPlayer();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: keyof typeof additions) => {
    setAdditions({
      ...additions,
      [field]: parseInt(e.target.value) || 0,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Check if any values are greater than 0 before submitting
    const totalPoints = additions.workersVacAdd + additions.solidersAdd + additions.spiesAdd;

    if (totalPoints === 0) {
      setError('שום אופציה לא נבחרה');
      return;
    }

    try {
      const token = localStorage.getItem('token');

      if (!token) {
        setError('No token found');
        return;
      }

      const response = await axios.post(`${BaseUrl}/trainpost`, additions, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        toast.success('האזרחים אומנו בהצלחה');
        // After successful submission, fetch the updated player data
        fetchPlayer();
      }
    } catch (err) {
      console.error('Error updating player data:', err);
      setError('שגיאה באימון אזרחים');
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
     
    <div className='w-widthcon gap-5 flex flex-row mt-5'>
      <form onSubmit={handleSubmit} className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl sm:flex-col sm:flex-start'>
        <div className='flex flex-col flex-start w-full'>
          <div className='flex flex-row w-full text-xl items-center mb-5 mr-2'>
            <i className="fa fa-th-list"></i> אימון ({formatNumber(player.enlisting)})

          </div>

          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-iron rounded-lg border border-white border-opacity-10'>
            חיילים
            <br />
            <input
              type="number"
              value={additions.solidersAdd}  // Controlled input with initial value 0
              onChange={(e) => handleInputChange(e, 'solidersAdd')}
              className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 w-widthcon my-3 rounded-md"
            />
          </div>

          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-wood rounded-lg border border-white border-opacity-10'>
            עבדים
            <br />
            <input
              type="number"
              value={additions.workersVacAdd}  // Controlled input with initial value 0
              onChange={(e) => handleInputChange(e, 'workersVacAdd')}
              className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 w-widthcon my-3 rounded-md"
            />
          </div>

          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-diamond rounded-lg border border-white border-opacity-10'>
            מרגלים
            <br />
            <input
              type="number"
              value={additions.spiesAdd}  // Controlled input with initial value 0
              onChange={(e) => handleInputChange(e, 'spiesAdd')}
              className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 w-widthcon my-3 rounded-md"
            />
          </div>

          <button type="submit" className="p-2 border mt-2 border-white border-opacity-20 backdrop-xl bg-white bg-opacity-10 hover:bg-opacity-20 transition-all duration-150 focus:scale-95 rounded-lg">
            אמן
          </button>
        </div>
        {error && <p className="text-red-500 mb-1 mt-5 text-center">{error}</p>}
      </form>

      <div className='bg-black bg-opacity-20 backdrop-blur-md border border-opacity-10 border-white w-1/2 p-5 flex flex-row rounded-xl sm:flex-col sm:flex-start'>
        <div className='flex flex-col flex-start w-full'>
          <div className='flex flex-row text-xl items-center mb-5 mr-2'>
            <i className="fa fa-th-list"></i> מידע
          </div>
          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-food rounded-lg border border-white border-opacity-10'>
            עבדים ללא תעסוקה
            <br />
            {player.workers_vac}
          </div>
          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-iron rounded-lg border border-white border-opacity-10'>
            חיילים
            <br />
            {player.soliders}
          </div>
          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-wood rounded-lg border border-white border-opacity-10'>
            עבדים
            <br />
            {player.workers}
          </div>
          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-diamond rounded-lg border border-white border-opacity-10'>
            מרגלים
            <br />
            {player.spies}
          </div>
          <div className='bg-black bg-opacity-20 w-full text-center mb-2 p-2 text-gold rounded-lg border border-white border-opacity-10'>
            מגוייסים
            <br />
            {player.enlisting}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Train;
