import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PieChart } from '@mui/x-charts/PieChart';
import BaseUrl from 'src/config';
import { formatNumber } from 'src/components/FormatNumber';

interface WorkerData {
  gold: number;
  iron: number;
  wood: number;
  food: number;
  workers: number;
  workers_vac: number;
  workers_gold: number;
  workers_iron: number;
  workers_wood: number;
  workers_food: number;
  gold_power: number;
  iron_power: number;
  wood_power: number;
  food_power: number;
  city: string; // Add city to the data structure
}

const upgradePrices = [
0, 500, 2500, 4000, 4706, 5536, 6513, 7662, 9014, 10605, 
12476, 14677, 17267, 20314, 23898, 28115, 33076, 38912, 45778, 53856, 
63359, 74538, 87691, 103164, 121367, 142782, 167976, 197615, 232485, 273506, 
321767, 378542, 445336, 523915, 616360, 725117, 853063, 1003586, 1180668, 1388997, 
1634085, 1922419, 2261629, 2660693, 3130172, 3682489, 4332264, 5096690, 5996000, 6296000
];

const Work = () => {
  const [workersData, setWorkersData] = useState<WorkerData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [currentUpgradeIndices, setCurrentUpgradeIndices] = useState<{ [key: string]: number }>({
    gold: 0,
    iron: 0,
    wood: 0,
    food: 0,
  });

  useEffect(() => {
    fetchWorkersData();
  }, []);

  const fetchWorkersData = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('לא נמצא אסימון');
        setLoading(false);
        return;
      }

      const response = await axios.get(`${BaseUrl}/workers`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      setWorkersData(response.data.player);
    } catch (err) {
      console.error('שגיאה בקבלת נתוני עובדים:', err);
      setError('שגיאה בקבלת נתונים');
    } finally {
      setLoading(false);
    }
  };

  const assignWorkers = async (type: string) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('לא נמצא אסימון');
        return;
      }

      await axios.put(
        `${BaseUrl}/workers/assign`,
        { type, amount },
        { headers: { Authorization: `${token}` } }
      );
      fetchWorkersData();
    } catch (err) {
      console.error(`שגיאה בהוספת עובדים ל-${type}:`, err);
    }
  };

  const removeWorkers = async (type: string) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('לא נמצא אסימון');
        return;
      }

      await axios.put(
        `${BaseUrl}/workers/remove`,
        { type, amount },
        { headers: { Authorization: `${token}` } }
      );
      fetchWorkersData();
    } catch (err) {
      console.error(`שגיאה בהסרת עובדים מ-${type}:`, err);
    }
  };

  const upgradeWorkerPower = async (type: string, upgradeCost: number) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError('לא נמצא אסימון');
        return;
      }

      await axios.put(
        `${BaseUrl}/workers/upgrade-power`,
        { type, cost: upgradeCost },
        { headers: { Authorization: `${token}` } }
      );

      setCurrentUpgradeIndices((prevIndices) => {
        const nextIndex = prevIndices[type] + 1;
        return {
          ...prevIndices,
          [type]: nextIndex < upgradePrices.length ? nextIndex : prevIndices[type],
        };
      });

      fetchWorkersData();
    } catch (err) {
      console.error(`שגיאה בשדרוג כוח עבור ${type}:`, err);
    }
  };

  if (loading) return <div>טוען...</div>;
  if (error) return <div>שגיאה: {error}</div>;
  if (!workersData) return <div>אין נתונים זמינים</div>;

  const cityMultipliers: Record<string, number> = {
    'גושן': 1,
    'מדבר סיני': 2,
    'הארץ המובטחת': 3,
  };
  const multiplier = cityMultipliers[workersData.city] || 1;

  const typeMapping: { [key: string]: { name: string; workersKey: keyof WorkerData; powerKey: keyof WorkerData } } = {
    gold: { name: 'זהב', workersKey: 'workers_gold', powerKey: 'gold_power' },
    iron: { name: 'ברזל', workersKey: 'workers_iron', powerKey: 'iron_power' },
    wood: { name: 'עץ', workersKey: 'workers_wood', powerKey: 'wood_power' },
    food: { name: 'אוכל', workersKey: 'workers_food', powerKey: 'food_power' },
  };

  return (
    <div className='w-widthcon flex gap-5 flex-row mt-5'>
      <div className='w-full flex gap-5'>
        {/* Free Workers Section */}
        <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>
          <i className="fa fa-th-list text-xl ml-1"></i>
          <span className="text-xl">עבדים פנויים ({workersData.workers_vac})</span>
          <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>

          {Object.keys(typeMapping).map((type) => {
            const { name, workersKey, powerKey } = typeMapping[type];
            const totalProduction = Number(workersData[workersKey]) * Number(workersData[powerKey]) * multiplier;

            return (
              <div
                key={type}
                className={`w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2 text-${type}`}
              >
                <span>{`עובדי ${name}`}</span>
                <span className={`${(multiplier === 2 || multiplier === 3) ? 'text-food' : ''}`}>{name} עבור כל עדכון: {`${formatNumber(totalProduction)}`} </span>
                <span>{`כוח עובדי ${name}: ${formatNumber(Number(workersData[powerKey]))}`}</span>
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(Number(e.target.value))}
                  className="bg-black bg-opacity-20 p-2 border border-dashed text-white text-center placeholder-white border-white border-opacity-20 mt-3 rounded-sm"
                />
                <div className="flex flex-row gap-2">
                <button
                  onClick={() => assignWorkers(type)}
                    className={`w-1/2 p-2 border mt-2 border-white border-opacity-20 rounded hover:bg-${type} hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-${type} bg-opacity-10`}
                >
                  הכנס
                </button>
<button
  onClick={() => removeWorkers(type)}
  className={`w-1/2 p-2 border mt-2 border-white border-opacity-20 rounded hover:bg-${type} hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-${type} bg-opacity-10`}
>
  הוצא
</button>
                </div>
              </div>
            );
          })}
        </div>

        {/* Active Workers Section */}
        <div className='w-1/2 bg-black backdrop-blur-lg bg-opacity-20 mb-5 p-5 rounded-lg border border-white border-opacity-10'>
          <i className="fa fa-university text-xl ml-1"></i>
          <span className="text-xl">עובדים פעילים</span>
          <div className='w-full bg-white h-px bg-opacity-10 mt-2 mb-2'></div>

          {Object.keys(typeMapping).map((type) => {
            const { name, powerKey } = typeMapping[type];
            const currentPower: any = workersData[powerKey];
            const nextLevelIndex = Math.floor(currentPower / 2);
            const upgradeCost = upgradePrices[nextLevelIndex] || 0;
            const canUpgrade = workersData.gold >= upgradeCost && nextLevelIndex < upgradePrices.length;

            return (
              <div key={type} className='w-full bg-white bg-opacity-10 rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'>
                <span>{`כוח עובדי ${name}: ${formatNumber(Number(currentPower))}`}</span>
<button
  onClick={() => upgradeWorkerPower(type, upgradeCost)}
  disabled={
    !canUpgrade ||
    (type === 'gold' && workersData.gold < upgradeCost) ||
    (type === 'iron' && workersData.iron < upgradeCost) ||
    (type === 'wood' && workersData.wood < upgradeCost) ||
    (type === 'food' && workersData.food < upgradeCost) ||
    nextLevelIndex >= upgradePrices.length
  }
  className={`p-2 border mt-2 ${
    canUpgrade &&
    (
      (type === 'gold' && workersData.gold >= upgradeCost) ||
      (type === 'iron' && workersData.iron >= upgradeCost) ||
      (type === 'wood' && workersData.wood >= upgradeCost) ||
      (type === 'food' && workersData.food >= upgradeCost)
    ) &&
    nextLevelIndex < upgradePrices.length
      ? `w-1/2 p-2 border mt-2 border-white border-opacity-20 rounded hover:bg-${type} hover:bg-opacity-30 hover:backdrop-blur-lg transition-all duration-150 text-xl bg-${type} bg-opacity-10 text-${type}`
      : 'border-gray-400 bg-black cursor-not-allowed text-white font-bold bg-opacity-60'
  } transition-all duration-150 text-xl rounded-lg w-full text-black`}
>
  {nextLevelIndex >= upgradePrices.length
    ? 'מקסימום שדרוג'
    : `שדרג כוח (${formatNumber(upgradeCost)} ${name})`}
</button>


              </div>
            );
          })}

          {Object.keys(typeMapping).map((type) => {
            const { name, workersKey } = typeMapping[type];

            return (
              <div
                key={type}
                className='w-full bg-white bg-opacity-10 backdrop-blur-lg rounded border border-white border-opacity-10 p-2 text-center flex flex-col mb-2'
              >
                <span>{`עובדי ${name}: ${formatNumber(Number(workersData[workersKey]))}`}</span>
              </div>
            );
          })}

          <div className='flex justify-center items-center w-150 h-110'>
            <PieChart
              series={[
                {
faded: { innerRadius: 10, additionalRadius: -10, color: 'gray' },
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  data: [
                    { id: 0, value: workersData.workers_gold, color: '#f39c12', label: 'עובדי זהב' },
                    { id: 1, value: workersData.workers_iron, color: '#00bc8c', label: 'עובדי ברזל' },
                    { id: 2, value: workersData.workers_wood, color: '#e63131', label: 'עובדי עץ' },
                    { id: 3, value: workersData.workers_food, color: '#31dce6', label: 'עובדי אוכל' },
                  ],
      innerRadius: 0,
      outerRadius: 80,
      paddingAngle: 0,
      cornerRadius: 0,
                },
              ]}
       margin={{ right: 5 }}
        width={200}
        height={200}
        slotProps={{
          legend: { hidden: true },
              }}
            />
          </div>
        </div>


        {/* Active Workers Section */}
        
      </div>
    </div>
  );
};

export default Work;
