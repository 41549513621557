import React, { useEffect, useState } from "react";
import axios from "axios";
import BaseUrl from "src/config";

interface SpellOption {
  duration: number;
  cost: number;
}

interface Spell {
  name: string;
  description: string;
  effect: string;
  options: SpellOption[];
  isActive?: boolean;
  remainingTime?: number; // Time remaining for active spells in milliseconds
}

const Magic = () => {
  const [spells, setSpells] = useState<Record<string, Spell> | null>(null);
  const [selectedDuration, setSelectedDuration] = useState<Record<string, number>>({});
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [activeTimers, setActiveTimers] = useState<Record<string, string>>({});
  const token = localStorage.getItem("token");

  if (!token) {
    setError("לא נמצא אסימון");
    setLoading(false);
  }

  // Function to fetch spells
  const fetchSpells = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/spells`, {
        headers: {
          Authorization: `${token}`,
        },
      });

      const fetchedSpells = response.data;

Object.entries(fetchedSpells).forEach(([key, spell]) => {
  // Explicitly type `spell` as `Spell`
  const typedSpell = spell as Spell;

  if (typedSpell.isActive && typedSpell.remainingTime) {
    startCountdown(key, typedSpell.remainingTime);
  }
});


      setSpells(fetchedSpells);
    } catch (err) {
      console.error("Error fetching spells:", err);
      setError("שגיאה בטעינת הקסמים");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSpells();
  }, []);

  const startCountdown = (key: string, remainingTime: number) => {
    const interval = setInterval(() => {
      remainingTime -= 1000;
      if (remainingTime <= 0) {
        clearInterval(interval);
        setActiveTimers((prev) => ({ ...prev, [key]: "00:00:00" }));
        fetchSpells();
      } else {
        const hours = String(Math.floor(remainingTime / (60 * 60 * 1000))).padStart(2, "0");
        const minutes = String(
          Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000))
        ).padStart(2, "0");
        const seconds = String(Math.floor((remainingTime % (60 * 1000)) / 1000)).padStart(2, "0");
        setActiveTimers((prev) => ({
          ...prev,
          [key]: `${hours}:${minutes}:${seconds}`,
        }));
      }
    }, 1000);
  };

  const getSpellKey = (name: string) => {
    const spellKey = Object.keys(spells || {}).find((key) => spells![key].name === name);
    if (!spellKey) {
      console.error("Spell key not found for name:", name);
    }
    return spellKey;
  };

  const handleBuySpell = async (spellName: string, duration: number) => {
    try {
      const spellKey = getSpellKey(spellName);
      if (!spellKey) {
        console.error("Invalid spell name:", spellName);
        return;
      }

      const selectedOption = spells![spellKey]?.options.find((opt) => opt.duration === duration);
      if (!selectedOption) {
        console.error("Selected option not found for spell:", spellName, "with duration:", duration);
        return;
      }

      const cost = selectedOption.cost;
      console.log("Payload being sent:", { spellName, duration, cost });

      const token = localStorage.getItem("token");
      if (!token) {
        alert("משתמש לא מאומת");
        return;
      }

      await axios.post(
        `${BaseUrl}/buy-spell`,
        { spellName, duration, cost },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );

      fetchSpells(); // Refresh spells after purchase
    } catch (err) {
      console.error("Error buying spell:", err);
      alert("שגיאה ברכישת הקסם");
    }
  };

  if (loading) return <div>טוען...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="w-widthcon">
      <h2 className="text-3xl font-bold text-center mb-6">קסמים זמינים</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-6">
{spells &&
  Object.entries(spells).map(([key, spell]) => {
    const typedSpell = spell as Spell; // Explicitly cast the spell to the correct type

    return (
      <div key={key} className="bg-gray-800 p-4 rounded-lg shadow-lg text-white">
        <h3 className="text-xl font-bold mb-2">{typedSpell.name}</h3>
        <p className="text-gray-400 mb-2">תיאור: {typedSpell.description}</p>
        <div className="mb-4">
          <label className="block text-sm mb-1">בחר משך זמן:</label>
          <select
            value={selectedDuration[key] || typedSpell.options[0]?.duration}
            onChange={(e) =>
              setSelectedDuration({ ...selectedDuration, [key]: Number(e.target.value) })
            }
            className="w-full p-2 bg-gray-700 text-white rounded focus:ring focus:ring-yellow-400"
          >
            {typedSpell.options.map((option, index) => (
              <option key={index} value={option.duration}>
                {option.duration > 0
                  ? `${option.duration / (60 * 60 * 1000)} שעות - ${option.cost} יהלומים`
                  : `${option.cost} יהלומים`}
              </option>
            ))}
          </select>
        </div>
        {typedSpell.isActive ? (
          <button
            disabled
            className="w-full bg-gray-600 text-white py-2 px-4 rounded transition-all"
          >
            {activeTimers[key] || "00:00:00"}
          </button>
        ) : (
          <button
            onClick={() =>
              handleBuySpell(
                typedSpell.name,
                selectedDuration[key] || typedSpell.options[0].duration
              )
            }
            className="w-full bg-yellow-500 hover:bg-yellow-600 text-black py-2 px-4 rounded transition-all"
          >
            רכוש קסם
          </button>
        )}
      </div>
    );
  })}

      </div>
    </div>
  );
};

export default Magic;
