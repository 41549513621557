import React, { useEffect, useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import { Switch } from '../Sidebar/Switch';
import { SidebarHeader } from '../Sidebar/SidebarHeader';
import { Diamond } from '../icons/Diamond';
import { BarChart } from '../icons/BarChart';
import { Global } from '../icons/Global';
import { InkBottle } from '../icons/InkBottle';
import { Book } from '../icons/Book';
import { Calendar } from '../icons/Calendar';
import { ShoppingCart } from '../icons/ShoppingCart';
import { Service } from '../icons/Service';
import { SidebarFooter } from '../Sidebar/SidebarFooter';
import { Badge } from '../Sidebar/Badge';
import { Typography } from '../Sidebar/Typography';
import { PackageBadges } from '../Sidebar/PackageBadges';
import { useAuth } from '../AuthContext';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import Discord from "../images/discord.png"
import BaseUrl from 'src/config';
import { formatNumber } from 'src/components/FormatNumber';
type Theme = 'light' | 'dark';
const themes = {
    light: {
        sidebar: {
            backgroundColor: '#ffffff',
            color: '#607489',
        },
        menu: {
            menuContent: '#fbfcfd',
            icon: '#0098e5',
            hover: {
                backgroundColor: '#c5e4ff',
                color: '#44596e',
            },
            disabled: {
                color: '#9fb6cf',
            },
        },
    },
    dark: {
        sidebar: {
            backgroundColor: '#000000',
            
            color: '#ffffff',
        },
        menu: {
            menuContent: '#ffffff',
            icon: '#ffffff9e',
            hover: {
                backgroundColor: '#000000',
                color: '#b6c8d9',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
};

// hex to rgba converter
const hexToRgba = (hex: string, alpha: number) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};


export const Playground: React.FC = () => {
    const [collapsed, setCollapsed] = React.useState(false);
    const [toggled, setToggled] = React.useState(false);
    const [broken, setBroken] = React.useState(false);
    const [rtl, setRtl] = React.useState(true);
    const [hasImage, setHasImage] = React.useState(false);
    const [theme, setTheme] = React.useState<Theme>('dark');
    const { logout } = useAuth();
    const location = useLocation();
    const [onlineMembers, setOnlineMembers] = useState<any[]>([]);
    const [avatarUrls, setAvatarUrls] = useState<string[]>([]);

    const isActiveLink = (...paths: string[]) => {
        return paths.some(path => location.pathname === path) ? 'active' : '';
    };

    const [playerStats, setPlayerStats] = useState<any>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [unreadCount, setUnreadCount] = useState(0);

    const fetchPlayerStats = async () => {
    try {
        const token = localStorage.getItem('token');
        
        // Fetch player stats
        const response = await axios.get(`${BaseUrl}/stats`, {
            headers: {
                Authorization: token,
            },
        });
        setPlayerStats(response.data);
        
        // Fetch unread message count
        const unreadResponse = await axios.get(`${BaseUrl}/messages/unread-count`, {
            headers: {
                Authorization: token,
            },
        });
        setUnreadCount(unreadResponse.data.unreadCount);

        // Add one more fetch
        // const discordResponse = await axios.get(`${BaseUrl}/discord-widget`, {
        //     headers: {
        //         Authorization: token,
        //     },
        // });

        // // Handle the Discord widget data
        // setOnlineMembers(discordResponse.data.onlineMembers);
        // setAvatarUrls(discordResponse.data.avatarUrls);

        setLoading(false);
    } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
    }
};


    useEffect(() => {
        fetchPlayerStats(); // Initial fetch

        const interval = setInterval(() => {
            fetchPlayerStats(); // Fetch data at regular intervals
        }, 5000); // Refresh every 5 seconds (adjust as needed)

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);
    // handle on RTL change event
    const handleRTLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRtl(e.target.checked);
    };
    
  // handle on theme change event
  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTheme(e.target.checked ? 'dark' : 'light');
  };

  // handle on image change event
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasImage(e.target.checked);
  };

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '16px',
      fontWeight: 400,
    },
    icon: {
      color: themes[theme].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: '#b6b7b9',
    },
    subMenuContent: ({ level }) => ({
      backgroundColor:
        level === 0
          ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 0.1)
          : 'transparent',
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[theme].menu.disabled.color,
      },
      '&:hover': {
        backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 0.4),
        color: themes[theme].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

return (
  <div style={{ display: 'flex', height: '100%', direction: rtl ? 'rtl' : 'ltr' }}>
    <Sidebar
      collapsed={collapsed}
      toggled={toggled}
      onBackdropClick={() => setToggled(false)}
      onBreakPoint={setBroken}
      image=""
      rtl={rtl}
      breakPoint="md"
      backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.8 : 0.8)}
      rootStyles={{ color: themes[theme].sidebar.color }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <SidebarHeader rtl={rtl} style={{ marginBottom: '24px', marginTop: '16px' }} />
        <div className="mb-5 ml-5 flex justify-end">
          <i
            className={`px-5 backdrop-blur-5 text-center flex justify-center rounded py-3 bg-black bg-opacity-20 hover:bg-black hover:bg-opacity-20 transition-all duration-200 cursor-pointer fa fa-arrow-${collapsed ? 'left' : 'right'}`}
            onClick={() => setCollapsed(!collapsed)}
          />
        </div>

        <div style={{ flex: 1, marginBottom: '32px' }}>
          {playerStats?.isAdmin ? (
            <>
              <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                <Typography
                  variant="body2"
                  fontWeight={600}
                  style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
                >
                  אדמין
                </Typography>
              </div>
              <Menu menuItemStyles={menuItemStyles}>
                <SubMenu label="אדמין פאנל" icon={<InkBottle />}>
                  <MenuItem>
                    <Link to="hwadmin/settings">הגדרות מערכת</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="hwadmin/news">ניהול חדשות</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="hwadmin/orders">ניהול חבילות והזמנות</Link>
                  </MenuItem>
                  <MenuItem>
                    <Link to="hwadmin/send_letters">מכתב לכולם</Link>
                  </MenuItem>
                </SubMenu>
              </Menu>
            </>
          ) : ""}

          <div style={{ padding: '0 24px', marginBottom: '8px' }}>
            <Typography
              variant="body2"
              fontWeight={600}
              style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px' }}
            >
              ראשי
            </Typography>
          </div>
          <Menu menuItemStyles={menuItemStyles}>
            <MenuItem icon={<BarChart />}>
              <Link to="/dashboard">בסיס</Link>
            </MenuItem>
            <MenuItem icon={<Global />} suffix={
              <Badge variant="danger" shape="circle">{playerStats?.city}</Badge>
            }>
              <Link to="/armies">דירוג</Link>
            </MenuItem>
            <SubMenu label="חנות" icon={<ShoppingCart />}>
              <MenuItem>
                <Link to="/weapons/attack">נשקי התקפה</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/weapons/defense">נשקי הגנה</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/weapons/spy">נשקי סיור</Link>
              </MenuItem>
            </SubMenu>
            <SubMenu label="משחקים והימורים" icon={<ShoppingCart />}>
              <MenuItem>
                <Link to="/lotto">גלגל המזל</Link>
              </MenuItem>
              <MenuItem>
                <Link to="/guess">נחש את המספר</Link>
              </MenuItem>
            </SubMenu>
            <MenuItem icon={<Book />} suffix={
              <Badge variant="danger" shape="circle">{formatNumber(playerStats?.enlisting)}</Badge>
            }>
              <Link to="train">אימון</Link>
            </MenuItem>
            <MenuItem icon={<Diamond />}>שדרוגים</MenuItem>
            <MenuItem icon={<ShoppingCart />} suffix={
              <Badge variant="danger" shape="circle">{playerStats?.clan}</Badge>
            }>
              <Link to="/clan">שבט</Link>
            </MenuItem>
            <MenuItem icon={<ShoppingCart />}><Link to="work">עבודות</Link></MenuItem>
            <MenuItem icon={<ShoppingCart />}><Link to="bank">בנק</Link></MenuItem>
<MenuItem
  icon={<ShoppingCart />}
  suffix={
    <Badge variant="danger" shape="circle">
      {(() => {
        const activeSpells = typeof playerStats?.active_spells === 'string'
          ? JSON.parse(playerStats.active_spells)
          : playerStats?.active_spells;
        return Array.isArray(activeSpells) ? activeSpells.length : 0;
      })()}
    </Badge>
  }
>
  <Link to="/magic">ניסים</Link>
</MenuItem>

            <MenuItem icon={<ShoppingCart />}>
            <Link to="/buy">קניית יהלומים</Link>
            </MenuItem>
            <MenuItem icon={<ShoppingCart />} suffix={<Badge variant="danger" shape="circle">{unreadCount}</Badge>}>
              <Link to="/letters/inbox">מכתבים</Link>
            </MenuItem>
            <MenuItem icon={<ShoppingCart />} suffix={
              <Badge variant="danger" shape="circle">5</Badge>
            }>
              דוחות
            </MenuItem>
            <MenuItem icon={<ShoppingCart />}><Link to="/settings">הגדרות</Link></MenuItem>
            <MenuItem icon={<ShoppingCart />}>צא לחופשה</MenuItem>
            <MenuItem icon={<ShoppingCart />}><Link className="text-red-600 font-semibold" to="/" onClick={logout}>התנתק</Link>
            </MenuItem>
          </Menu>

         
        </div>
      </div>
    </Sidebar>
  </div>
);

};
export default Playground;
